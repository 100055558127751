@import '../../../../styles/helpers/mixins.scss';
@import '../../../../styles/helpers/variables.scss';

.header {
  @include mq('md', max) {
    padding: 8px 16px;
  }

  padding: 8px 32px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid rgba(51, 44, 35, 0.1);

  > div {
    display: flex;
    align-items: center;

    &.left {
      gap: 32px;

      > span {
        font-weight: 500;

        color: $dark;
        opacity: 0.6;
      }
    }

    &.middle {
      gap: 24px;

      > button {
        height: unset;
        padding: 8px 24px;

        font-size: 16px;

        background-color: $dark;
      }
    }

    &.right {
      gap: 32px;

      > .buttons {
        display: flex;
        align-items: center;
        gap: 16px;

        > button {
          height: unset;
          padding: 8px 24px;

          font-size: 16px;
        }
      }
    }
  }
}

