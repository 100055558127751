@import '../../../../styles/helpers/variables.scss';

.device-picker {
  display: flex;
  align-items: center;
  gap: 8px;

  position: relative;

  cursor: pointer;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 24px;
    height: 24px;

    fill: $dark;
    opacity: 0.6;
  }

  .dropdown {
    position: absolute;
    top: 24px;
    right: 0;
    left: 0;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px rgba(51, 44, 35, 0.08);
    background-color: $white;

    > button {
      width: 100%;
      padding: 8px 16px;

      display: flex;
      justify-content: center;

      font-size: 16px;
      text-wrap: nowrap;
      text-align: left;
      opacity: 0.6;

      border: unset;
      background-color: unset;
      color: $dark;

      cursor: pointer;

      &:hover {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        background-color: $light;

        > svg {
          fill: #000000;
          opacity: 1;
        }
      }
    }
  }
}

