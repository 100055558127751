@import '../../../../styles//helpers/variables.scss';

.subscription_card {
      width: 100%;
      max-width: 360px;
      padding: 24px 16px;

      position: relative;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 24px;

      border-radius: 16px;
      border: 1px solid rgba(51, 44, 35, 0.2);
      background-color: $white;

      &__badge {
        width: 200px;
        padding: 4px 8px;

        position: absolute;
        right: -48px;
        top: 40px;
        transform: rotate(45deg);

        background-color: #09bc8a;
        color: $white;
        text-align: center;
        text-transform: uppercase;

        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: 0.7px;
      }

      &__header {
        > h3 {
          margin-bottom: 8px;

          text-align: center;
          font-size: 24px;
          font-weight: 600;
          line-height: 150%;
        }

        > p {
          text-align: center;

          > span:first-child {
            font-size: 40px;
            font-weight: 700;
            line-height: 150%;
          }

          > span:last-child {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
          }
        }
      }

      > button {
        max-width: unset;
        width: 100%;

        font-size: 16px;
      }

      &__features {
        display: flex;
        flex-direction: column;
        gap: 8px;

        > li {
          display: flex;
          gap: 8px;

          > svg {
            min-width: 24px;
            height: 24px;

            fill: $blue;
          }
        }
      }
    }