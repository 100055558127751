@import '../../styles/helpers/variables.scss';
@import '../../styles/helpers/mixins.scss';

.modal-container {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  background-color: rgba(0, 0, 0, 0.5);

  &__content {
    @include mq('md') {
      padding: 24px;
    }

    width: 100%;
    max-width: 568px;
    padding: 24px 16px;
    margin: 0 8px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px rgba(51, 44, 35, 0.08);

    > header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h3 {
        margin: 0;

        font-size: 24px;
        font-weight: 600;
        line-height: 150%;
      }

      > button {
        background: none;
        border: none;
        cursor: pointer;
      }
    }
  }

  .buttons {
    @include mq('md') {
      flex-direction: row;
      justify-content: end;
    }

    display: flex;
    flex-direction: column;
    gap: 16px;

    > button {
      @include mq('md') {
        width: unset;
      }

      max-width: unset;
      width: 100%;

      &:first-child {
        @include mq('md') {
          order: 0;
        }

        order: 1;
      }
    }
  }
}

