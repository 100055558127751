@import "../../styles/index.scss";

.o-hero {
  min-height: 100vh;
  padding-top: 72px;
  .container {
    padding-top: 48px;
    padding-bottom: 32px;
  }
  @include mq("lg") {
    .container {
      padding-top: 48px;
      padding-bottom: 48px;
    }
    &-row {
      .left {
        width: 70%;
      }
    }
  }
  @include mq("xl") {
    padding-top: 120px;
    display: flex;
    align-items: center;
    .container {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    &-row {
      display: flex;
      .left {
        width: 50%;
      }
      .right {
        width: 50%;
      }
    }
  }
}
