@import '../../../../styles/helpers/mixins.scss';

.basic-info-section {
  @include mq('md') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  display: flex;
  flex-direction: column;
  gap: 16px;
}

