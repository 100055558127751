@import '../../../../styles/helpers/mixins.scss';

.list {
  @include mq('md') {
    flex-direction: row;
    flex-wrap: wrap;
  }

  display: flex;
  flex-direction: column;
  gap: 16px;
}

