@import "../../styles/index.scss";

.o-features {
  @include mq("md") {
    &-row {
      display: flex;
      flex-wrap: wrap;
      .item {
        padding: 12px;
        &.title {
          padding: 0 12px;
        }
      }
    }
  }
  @include mq("xl") {
    &-row {
      .item {
        &.title {
          width: 33.333%;
          padding: 12px;
        }
      }
    }
  }
}

.m-features {
  &-card {
    cursor: context-menu;
    height: auto;
    &__inner {
      padding: 24px;
      background: linear-gradient(0deg, #ffffff, #ffffff), #e9f5ff;
      border-radius: 16px;
      height: 100%;
      .icon {
        margin-bottom: 22px;
        width: 72px;
        height: 72px;
        & > * {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .title {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 16px;
      }
    }
  }
  &-slider {
    .swiper-pagination {
      position: static;
      height: auto;
      margin-top: 12px;
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        background-color: transparent;
        border: 1px solid $green;
        opacity: 1;
        &.swiper-pagination-bullet-active {
          background-color: $green;
        }
      }
    }
  }
  @include mq("md") {
    &-card {
      width: 50%;
    }
  }
  @include mq("xl") {
    &-card {
      width: 33.333%;
    }
  }
}
