.divider {
  width: 100%;

  display: flex;
  align-items: center;
  text-align: center;

  opacity: 0.3;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #332C23;
  }

  >span {
    padding: 0 8px;
    color: #332C23;
  }
}
