@import '../../styles/helpers/mixins.scss';

.create-new-password-page {
    > main {
    @include mq('xl') {
      padding: 56px 32px;
    }

    padding: 24px 16px 40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
      margin-bottom: 24px;

      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }

    > form {
      max-width: 520px;
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 16px;

      > button {
        margin-top: 8px;
        width: 100%;
        max-width: unset;
      }
    }
  }
}