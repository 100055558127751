@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Regular.woff") format("woff"),
    url("../fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Medium.woff") format("woff"),
    url("../fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Semibold.woff") format("woff"),
    url("../fonts/Gilroy-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Bold.woff") format("woff"),
    url("../fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
