@import '../../../../styles/helpers/variables.scss';
@import '../../../../styles/helpers/mixins.scss';

.change-password-modal {
  > form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .current_password {
      display: flex;
      flex-direction: column;
      gap: 16px;

      > p {
        align-self: end;

        font-weight: 500;
        line-height: 150%;

        > button {
          background-color: unset;
          border: unset;

          color: $blue;
          text-decoration: underline;

          font-family: inherit;
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;

          cursor: pointer;
        }
      }
    }
  }
}

