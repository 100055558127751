@import '../../styles/helpers/mixins.scss';

.minimal_header {
    @include mq('xl') {
        padding: 32px;
    }

    padding: 8px 16px;

    display: flex;
    justify-content: center;

    border-bottom: 1px solid rgba(51, 44, 35, 0.10);
}
