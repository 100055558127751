@import '../../styles/helpers/variables.scss';
@import '../../styles/helpers/mixins.scss';

.checkout_page {
  padding: 24px 16px 40px;

  > h1 {
    margin-bottom: 16px;

    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 130%;
  }

  > h2 {
    margin-bottom: 32px;

    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    text-align: center;

    > span {
      color: $blue;
    }
  }

  .payment_methods_container {
    @include mq('md') {
      margin-left: auto;
      margin-right: auto;
      max-width: 520px;
    }

    margin-bottom: 32px;

    display: flex;
    flex-direction: column;
    gap: 32px;

    > h3 {
      margin: 0;

      font-size: 24px;
      font-weight: 600;
      line-height: 150%;
    }

    .payment_methods {
      @include mq('md') {
        flex-direction: row;
        gap: 32px;
        justify-content: space-between;
      }

      display: flex;
      flex-direction: column;
      gap: 24px;

      &__item {
        @include mq('md') {
          height: 120px;

          flex-direction: column;
          gap: 4px;
        }

        width: 100%;
        height: 72px;
        padding: 24px;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        font-size: 18px;
        font-weight: 500;
        line-height: 150%;

        border: 2px solid transparent;
        border-radius: 8px;
        background-color: $white;

        cursor: pointer;

        &.active {
          border-color: $blue;
        }

        .credit_card_icon {
          width: 48px;
          height: 48px;

          fill: $blue;
        }
      }
    }
  }

  .form_container {
    @include mq('md') {
      margin-left: auto;
      margin-right: auto;
      max-width: 520px;
    }

    margin-bottom: 32px;

    > button {
      width: 100%;
      max-width: unset;
      margin-top: 32px;
    }
  }

  .paypal_container {
    @include mq('md') {
      margin-left: auto;
      margin-right: auto;
      max-width: 520px;
    }

    > p {
      margin-bottom: 32px;

      text-align: center;
      font-weight: 500;
      line-height: 150%;
    }
    
    > button {
      width: 100%;
      max-width: unset;
    }
  }

  .accepted_payments {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > p {
      text-align: center;
      font-weight: 500;
      line-height: 150%;
    }

    > ul {
      display: flex;
      justify-content: center;
      gap: 8px;

      > li > img {
        width: 56px;
        height: 40px;
      }
    }
  }
}

