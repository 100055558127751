@import '../../../styles/helpers/mixins.scss';
@import '../../../styles/helpers/variables.scss';

.switch {
  @include mq('md') {
    width: 432px;
  }

  width: 100%;
  margin-bottom: 32px;
  padding: 1.5px 1px;

  border-radius: 8px;
  border: 1px solid rgba(51, 44, 35, 0.2);

  > button {
    @include mq('md') {
      font-size: 16px;
    }

    width: 50%;
    padding: 16px 4px;

    font-size: 14px;
    font-weight: 600;
    line-height: 150%;

    border: unset;
    border-radius: 8px;
    background-color: unset;

    cursor: pointer;

    > span {
      color: #09bc8a;
    }

    &.active {
      background-color: #09bc8a;
      color: $white;

      > span {
        color: $dark;
      }
    }
  }
}
