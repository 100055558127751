@import "../../styles/index.scss";

.o-process {
  padding-bottom: 32px;
  &-items {
    counter-reset: items;
    .item {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
      &-inner {
        &:before {
          display: block;
          margin-bottom: 16px;
          counter-increment: items;
          content: counter(items);
          color: $blue;
          font-size: 48px;
          line-height: 120%;
          font-weight: 700;
        }
      }
    }
  }
  .top {
    margin-bottom: 64px;
  }
  @include mq("md") {
    h2 {
      margin-right: 1rem;
    }
    .top {
      .container {
        display: flex;
        justify-content: space-between;
      }
    }
    &-items {
      width: 53.5%;
      flex-shrink: 0;
    }
  }
  @include mq("xl") {
    padding-bottom: 60px;
    .top {
      margin-bottom: 120px;
      .container {
        display: block;
      }
    }

    &-items {
      width: auto;
      display: flex;
      flex-wrap: wrap;
      margin-left: -12px;
      margin-right: -12px;
      .item {
        width: 33.333%;
        padding-left: 12px;
        padding-right: 12px;
        margin-bottom: 0;
      }
    }
  }
}
