@import '../../../../styles/helpers/variables.scss';

.choose-domain-modal {
  .search {
    margin-bottom: 40px;
    height: 56px;

    display: flex;
    gap: 8px;

    > div {
      width: 100%;
    }

    .search_button {
      flex: 0 0 56px;

      background-color: $blue;
      border: unset;
      border-radius: 8px;

      cursor: pointer;

      > svg {
        width: 24px;
        height: 24px;

        fill: $white;
      }
    }
  }

  .suggested_domains {
    display: flex;
    flex-direction: column;
    gap: 24px;

    > h3 {
      margin: 0;
    }

    > ul {
      display: flex;
      flex-direction: column;
      gap: 20px;

      li {
        display: flex;
        justify-content: space-between;

        font-weight: 500;

        > a {
          font-weight: 600;
          text-decoration: none;
          color: $blue;
        }
      }
    }
  }
}

