@import '../../../../styles/helpers/mixins.scss';
@import '../../../../styles/helpers/variables.scss';

.section {
  @include mq('xl') {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  display: none;

  h3 {
    margin: 0;

    font-size: 24px;
    font-weight: 600;

    color: $dark;
  }

  &.active {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

