@import '../../styles/index.scss';

.color1 {
  color: #17a8d8;
}

.color2 {
  color: #6184f4;
}

.color3 {
  color: #048abf;
}

.steps {
  &__header {
    @include mq('xs') {
      display: grid;
      justify-items: center;
      gap: 16px;
    }
    @include mq('lg') {
      display: flex;
      justify-content: space-between;
    }

    align-items: center;
    border-bottom: 1px solid rgba(51, 44, 35, 0.1);
    padding: 32px;
    margin-bottom: 56px;
  }

  &__main {
    @include mq('xs') {
      width: 100%;
    }
    @include mq('lg') {
      width: 50%;
    }

    position: sticky;
    top: 0;
    overflow-y: scroll;
    height: 100vh;
  }

  &__heading {
    margin-bottom: 16px;
  }

  &__input {
    margin-bottom: 40px;
  }

  &__textarea {
    height: 168px;
  }

  &__label {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: $dark;
    margin-bottom: 16px;
  }

  &__container {
    padding: 0 32px;
  }

  &__multi-container {
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
  }

  &__button {
    @include mq('xs') {
      margin: 32px auto;
    }
    @include mq('lg') {
      margin-left: calc(100% - 96px);
    }
  }

  &__layout {
    display: flex;
  }
  
  &__template-container {
    @include mq('xs') {
      display: none;
    }

    @include mq('lg') {
      display: block;
    }

    width: 50%;
  }

  &__template {
    background-color: $white;

    &__header__details {
      background-color: #121f38;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      width: 100%;
      color: white;
      padding: 0 10px;

      &__worktime,
      &__contact {
        display: flex;
        align-items: center;

        > p {
          font-size: 11.4px;
          font-weight: 400;
          letter-spacing: 0;
        }
      }

      &__worktime > p {
        padding-left: 5px;
      }

      &__contact > p {
        padding: 0 5px;
      }
    }
    &__header__main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 10px;
      height: 90px;

      > h2 {
        margin: 0;
        display: flex;
        align-items: center;

        > span {
          font-size: 25px;
          font-weight: 800;
          letter-spacing: 0;
          line-height: 42.7px;
        }
      }

      &__navigation {
        display: flex;
        align-items: center;

        &__button {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
          height: 50px;
          padding: 0 16px;
        }

        > nav > ul {
          display: flex;

          :last-child {
            margin-right: 10px;
          }

          > li {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin: 0 5px;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0;

            & > * {
              &.active {
                color: $blue;
              }
            }
          }
        }
      }
    }

    &__section {
      padding: 20px 10px;

      &__main {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__img {
          width: 45%;

          > img {
            width: 100%;
            border-radius: 16px;
          }
        }

        &__text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 45%;

          > p {
            margin: 40px 0;
            // font-size: 15px;
            // font-weight: 500;
            // left: 0;
            // letter-spacing: 0;
          }

          > h3 {
            font-size: 38px;
            font-weight: 700;
            letter-spacing: 0;
            margin: 0;
            // line-height: 76.8px;
          }

          &__time {
            display: flex;

            > button {
              margin-right: 30px;
              background-color: #17a8d8;
              border-radius: 8px;
              border: none;
              display: inline-flex;
              gap: 8px;
              height: 56px;
              justify-content: center;
              padding: 0 10px;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: 0;
            }

            &__details {
              display: flex;
              flex-direction: column;
              justify-content: center;

              > p {
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0;
                opacity: 75%;
              }
            }
          }
        }
      }

      &__about {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__img {
          position: relative;
          width: 45%;
          height: 200px;

          :first-child {
          position: relative;

            width: 55%;
            top: 0;
            left: 0;
            z-index: 1;
          }

          :last-child {
            width: 55%;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 3;
          }

          > img {
            border-radius: 16px;
          }
        }

        &__text {
          width: 45%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          > h3 {
            font-size: 38px;
            font-weight: 700;
            left: 0;
            letter-spacing: 0;
          }
        }
      }

      &__service {
        display: flex;
        align-items: center;
        flex-direction: column;

        &__details {
          display: flex;

          &__img {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            gap: 30px;
            justify-content: center;

            > label {
              display: flex;
              align-items: center;
              position: relative;
              text-align: center;
              width: 45%;
              margin: 0;

              > img {
                width: 100%;
                border-radius: 16px;
                margin: 0;
              }

              > p {
                background-color: white;
                padding: 15px;
                position: absolute;
                bottom: 0;
                left: 0;
                border-radius: 0px 16px 0px 0px;
                font-weight: 600;

                .hide {
                  visibility: hidden;
                  opacity: 0;
                  transition: visibility 0.5s;
                  height: 0px;
                }
              }

              > p:hover > .hide {
                height: auto;
                visibility: visible;
                color: #332c23;
                text-align: start;
                opacity: 1;
                transition: opacity 0.75s, visibility 0.75s;
              }
            }
          }
        }

        h3 {
          font-size: 38px;
          font-weight: 700;
          letter-spacing: 0;
        }
      }

      &__team {
        display: flex;
        align-items: center;
        flex-direction: column;

        > h3 {
          font-size: 38px;
          font-weight: 700;
          letter-spacing: 0;
        }

        &__wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          &__img {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 20%;
            margin: 10px;

            > p {
              text-transform: uppercase;
              font-size: 11px;
              font-weight: 400;
              left: 0;
              letter-spacing: 0.8px;
            }

            h4 {
              font-size: 14px;
              font-weight: 600;
              left: 0;
              letter-spacing: 0;
            }

            > img {
              width: 100%;
            }
          }
        }
      }

      &__questions {
        display: flex;
        align-items: center;
        flex-direction: column;

        &__list {
          width: 70%;
        }

        > h3 {
          font-size: 38px;
          font-weight: 700;
          letter-spacing: 0;
        }
      }

      &__form {
        background-color: #eef7ff;
        display: flex;
        justify-content: space-between;
        padding: 20px 10px;

        &__img {
          width: 45%;
          display: flex;
          align-items: center;

          img {
            width: 100%;
          }
        }

        &__inputs {
          width: 45%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h3 {
            font-size: 35px;
            font-weight: 700;
            letter-spacing: 0;
          }

          form {
            padding: 10px 0;
            display: flex;
            flex-direction: column;

            div {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              input {
                width: 45%;
                margin: 5px;
                border: 1px solid;
                border-color: #121f3833;
                border-radius: 8px;
                padding: 14px 16px;
              }
            }

            textarea {
              margin: 5px;
              border: 1px solid;
              border-color: #121f3833;
              border-radius: 8px;
              padding: 14px 16px;
              font-family: 'Inter-Regular', Helvetica;
            }

            button {
              color: #ffffff;
              font-size: 15px;
              font-weight: 500;
              height: 45px;
              max-width: 130px;
              margin: 5px;
              border-radius: 8px;
              display: inline-flex;
              border: none;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;

      &__main {
        width: 100%;
        padding: 25px 10px;
        display: flex;
        background-color: #121f38;
        color: white;
        justify-content: space-between;

        &__logo {
          display: flex;
          flex-direction: column;

          h3 {
            font-size: 25px;
            font-weight: 800;
            letter-spacing: 0;
            padding: 5px 0;
            margin: 0;
          }

          &__social {
            display: flex;
            padding: 5px 0;

            svg {
              margin-right: 5px;
            }
          }
        }

        &__nav {
          display: flex;

          ul {
            display: flex;
            flex-direction: column;

            > li {
              font-size: 11px;
              cursor: pointer;
              font-weight: 400;
            }
          }
        }

        &__worktime {
          svg {
            margin: 5px;
          }

          &__details {
            display: flex;
            flex-direction: column;

            p {
              font-size: 11px;
              font-weight: 400;
            }
          }
        }

        &__contacts {
          display: flex;
          flex-direction: column;

          &__details {
            margin-bottom: 5px;
            display: flex;
            align-items: center;

            svg {
              margin-right: 5px;
            }

            p {
              font-size: 11px;
              font-weight: 400;
            }
          }
        }

        &__map {
          width: 20%;
          align-items: center;

          img {
            width: 100%;
          }
        }

        > div {
          display: flex;
        }
      }

      &__copyrights {
        color: white;
        width: 100%;
        align-items: center;
        background-color: #09101e;
        display: flex;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .back-to-top {
      border: none;
      border-radius: 50%;
      background-color: #17a8d8;
      position: fixed;
      right: 25px;
      bottom: 20px;
      font-size: 2rem;
      z-index: 1;
      cursor: pointer;
      color: white;
    }
  }

  &__inputs {
    position: relative;
  }

  &__photo-input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin-right: 24px;
  }

  &__photo {
    position: relative;
  }

  &__photo-choice {
    display: flex;
    gap: 24px;
    width: fit-content;
    align-items: center;
    margin-bottom: 40px;
  }

  &__photo-preview {
    max-width: 200px;
    max-height: 160px;
    object-fit: contain;
    border-radius: 8px;
  }

  &__photo-preview-container {
    display: flex;
    gap: 32px;
    margin-bottom: 40px;
  }

  &__photo-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 100;
    width: 24px;
    height: 24px;
    background: white;
    color: $blue;
  }

  &__image-block {
    position: relative;
  }

  &__services {
    display: grid;
    gap: 40px;
    margin-bottom: 40px;
  }

  &__suggestions {
    display: grid;
    gap: 40px;
    margin-bottom: 40px;
  }

  &__select-container {
    margin-bottom: 40px;
    display: grid;
    gap: 40px;

    input {
      padding: 8px !important;
    }
  }

  &__details-container {
    display: grid;
    gap: 40px;
    margin-bottom: 40px;
  }
}
