.service-suggestion {
  &__topics {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__description-container {
    padding: 16px;
    border: 1px solid #332c2333;
    display: grid;
    gap: 16px;
    border-radius: 8px;
  }
}
