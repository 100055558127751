@import '../../../../styles/helpers/mixins.scss';
@import '../../../../styles/helpers/variables.scss';

.tabs {
  @include mq('xl') {
    display: none;
  }
  
  padding: 24px 0 0;

  display: flex;

  overflow-x: scroll;

  > li {
    opacity: 0.6;

    &.active {
      border-bottom: 2px solid $blue;
      opacity: 1;
    }

    > button {
      padding: 8px 16px;

      font-size: 18px;
      font-weight: 500;
      text-wrap: nowrap;

      border: none;
      background-color: transparent;
      color: $dark;

      cursor: pointer;
    }
  }
}
