@import '../../../../styles/helpers/mixins.scss';
@import '../../../../styles/helpers/variables.scss';

.website-section {
  @include mq('md') {
    flex-direction: row;
  }

  display: flex;
  flex-direction: column;
  gap: 24px;

  > img {
    max-width: 360px;
    width: 100%;
    max-height: 200px;
    height: 100%;
    object-fit: cover;

    border-radius: 8px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;

    &--text {
      > h4 {
        margin-bottom: 24px;

        font-size: 18px;
        font-weight: 600;
      }

      > p {
        @include mq('xl') {
          flex-direction: row;
        }

        display: flex;
        flex-direction: column;

        &:first-of-type {
          margin-bottom: 16px;
        }

        > span,
        > a {
          @include mq('xl') {
            flex: 1 1 100%;
          }

          font-weight: 500;
        }

        > a {
          display: flex;
          align-items: center;
          gap: 8px;

          color: $blue;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &--buttons {
      display: flex;
      gap: 16px;

      > button {
        @include mq('xl') {
          min-width: unset;
        }

        min-width: 100%;
        height: unset;
        padding: 8px 24px;

        text-wrap: nowrap;

        &:first-of-type {
          @include mq('xl') {
            display: flex;
          }

          display: none;
        }
      }
    }
  }
}

