@import "../../styles/index.scss";

.o-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 71;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 72px;
  display: flex;
  align-items: center;
  transition: box-shadow 0.4s;
  box-shadow: 0px 1px 22px 0px rgba(30, 150, 252, 0);
  &.active {
    box-shadow: 0px 1px 22px 0px rgba(30, 150, 252, 0.1);
  }
  &.center {
    border-bottom: 1px solid rgba(51, 44, 35, 0.1);
    .o-header-row {
      justify-content: center;
      .right {
        display: none;
      }
    }
  }
  &.create-beetwen {
    border-bottom: 1px solid rgba(51, 44, 35, 0.1);
    .o-header-row {
      //flex-direction: column;
      .left {
        .logo {
          width: 110px;
        }
      }
      .right {
        display: none;
      }
      .a-btn {
        width: fit-content;
        padding: 0 12px;
        font-size: 16px;
        height: 48px;
      }
    }
  }
  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right {
      display: flex;
      align-items: center;
      &.e--desk {
        display: none;
      }
      .a-btn {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .logo {
      width: 118px;
      height: 56px;
      position: relative;
      display: block;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .m-menu {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    li {
      margin-right: 1rem;
      cursor: pointer;
      & > * {
        &.active {
          color: $blue;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  @include mq("md") {
    &.create-beetwen {
      .o-header-row {
        .left {
          .logo {
            width: 118px;
          }
        }
        .right {
          display: none;
        }
        .a-btn {
          width: fit-content;
          padding: 0 32px;
          font-size: 20px;
          height: 56px;
        }
      }
    }
  }
  @include mq("lg") {
    &.create-beetwen {
      width: 50%;
    }
  }
  @include mq("xl") {
    height: 120px;
    &-row {
      .right {
        &.e--desk {
          display: flex;
        }
        &.e--mobile {
          display: none;
        }
      }
    }
  }
  @include mq("xxl") {
    &-row {
      .right {
        .a-btn {
          margin-right: 2rem;
        }
      }
    }
    .m-menu {
      margin-right: 2rem;
      li {
        margin-right: 2rem;
        & > * {
          font-size: 1.125rem;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
