@import './components/section.scss';
@import './components/input.scss';
@import './helpers/index.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  a {
    color: inherit;
  }
}

body {
  color: $dark;
  background-color: $light;
  font-family: 'Gilroy';
  font-weight: 400;
  line-height: 150%;
  .o-header {
    background-color: $light;
  }
  &.dark-theme {
    color: $light;
    background-color: $dark;
    .o-header {
      background-color: $dark;
    }
    .m-features-card {
      &__inner {
        background: linear-gradient(0deg, #3d342a, #3d342a), #393127;
      }
    }
    .a-btn.white {
      background-color: $dark;
      &:hover {
        background-color: #1e94fc11;
      }
    }
  }
}
.e--blue {
  color: $blue;
}
.container {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.page-subtitle {
  font-weight: 500;
  margin-bottom: 40px;
  font-size: 20px;
}
h1 {
  font-weight: 700;
  font-size: 40px;
  line-height: 130%;
}
h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
}
h3 {
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 150%;
}
h1,
h2 {
  margin-bottom: 40px;
  em {
    white-space: nowrap;
    background-color: $green;
    color: $white;
    padding: 4px 4px;
    border-radius: 8px;
    line-height: 100%;
    display: inline-block;
  }
}
.create-nav {
  background-color: $light;
  &::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    bottom: calc(100% - 4px);
    height: 20px;
    background: linear-gradient(0deg, #f9fafb, #f9fafbdd 35%, #f9fafb00);
    pointer-events: none;
  }
  &__line {
    height: 4px;
    background-color: #d9d9d9;
    position: relative;
    width: 100%;
    &__inner {
      border-radius: 2px;
      background-color: $blue;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }
  }
}
@include mq('md') {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  h1 {
    font-size: 64px;
    line-height: 120%;
  }
  h2 {
    font-size: 48px;
  }
  h1,
  h2 {
    em {
      padding: 5px 5px;
    }
  }
}
@include mq('xl') {
  .container {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
