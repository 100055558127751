@import "../../styles/index.scss";

.o-tryit {
  background-color: $blue-light;
  border-radius: 16px;
  padding-top: 64px;
  padding-bottom: 64px;
  .right {
    margin-bottom: 40px;
    .input {
      margin-bottom: 40px;
    }
    .a-btn {
      width: 100%;
      max-width: 100%;
    }
  }
  .left {
    width: 100%;
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 80%;

    .img {
      position: absolute;
      left: 0;
      top: 0;
      overflow: hidden;
      border-radius: 16px;
      width: 100%;
      height: 100%;
      & > * {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }
  @include mq("md") {
    .right {
      .a-btn {
        width: fit-content;
      }
    }
  }
  @include mq("lg") {
    &-row {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      .right {
        width: 49%;
        margin-bottom: 0;
      }
      .left {
        width: 46%;
        padding-bottom: 38%;
      }
    }
  }
  @include mq("xl") {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
