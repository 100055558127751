@import '../../../../styles/helpers/variables.scss';
@import '../../../../styles/helpers/mixins.scss';

.table {
  @include mq('md', 'max') {
    display: none;
  }

  max-width: 672px;
  border-collapse: collapse;

  tr:last-child {
    td:first-child {
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-bottom-right-radius: 10px;
    }
  }

  tr:first-child {
    td:first-child {
      border-top-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
    }
  }

  > thead {
    > tr > th {
      padding-bottom: 4px;
      padding-left: 32px;

      color: $dark;
      opacity: 0.4;

      font-weight: 500;
      line-height: 150%;
      text-align: left;

      &:first-child {
        padding-left: 24px;
      }
    }
  }

  > tbody {
    > tr {
      &:nth-child(n + 2) {
        border-top: 1px solid rgba(51, 44, 35, 0.2);
      }

      > td {
        padding: 16px 0;
        padding-left: 32px;

        color: $dark;
        background-color: $white;

        font-size: 18px;
        font-weight: 500;
        line-height: 150%;
        text-align: left;

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }
}

.mobileTable {
  @include mq('md') {
    display: none;
  }

  width: 100%;
  background-color: $white;

  > li {
    padding: 16px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(51, 44, 35, 0.2);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    > span:first-child {
      line-height: 150%;
      opacity: 0.6;
    }

    > span:last-child {
      font-size: 18px;
      line-height: 150%;
    }
  }

  &__order-number {
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
  }

  &__amount {
    font-size: 20px;
    font-weight: 600;
    line-height: 150%;
  }

  &__actions {
    display: flex;
    justify-content: end;
    gap: 24px;
  }
}

.actionButton {
  background-color: unset;
  border: unset;

  cursor: pointer;

  > svg {
    width: 24px;
    height: 24px;

    opacity: 0.5;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
}

