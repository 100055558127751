@import '../../styles/helpers/index.scss';

.color-picker {
  display: grid;
  width: 100%;
  margin-bottom: 40px;
  @include mq('xs') {
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    justify-items: center;
    gap: 24px;
  }

  @include mq('lg') {
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    justify-items: center;
    gap: 36px;
  }

  @include mq('xxl') {
    grid-template-columns: repeat(3, 1fr);
    height: 88px;
    gap: 24px;
  }

  &__color {
    width: 80px;
    height: 56px;
  }

  &__container {
    display: grid;
    width: fit-content;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 16px;
    border: 2px solid transparent;
    border-radius: 8px;
    cursor: pointer;
  }

  &__picked {
    border: 2px solid #1e96fc;
    transform: scale(1.02);
    cursor: default;
  }
}
