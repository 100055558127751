@import '../../styles/helpers/index.scss';


.a-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: fit-content;
  padding: 0 32px;
  height: 56px;
  background-color: $blue;
  color: $white;
  border: none;
  border-radius: 8px;
  max-width: 280px;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  font-family: "Gilroy";
  text-decoration: none;
  transition: 0.25s;
  text-align: center;
  &:hover {
    opacity: 0.85;
  }
  &.white {
    background-color: white;
    color: $blue;
    border: 1px solid $blue;
    &:hover {
      background-color: #1e94fc11;
    }
  }
  &.transparent {
    background-color: transparent;
    color: $blue;
    border: 1px solid $blue;
    &:hover {
      background-color: #1e94fc11;
    }
  }
  &-prev {
    font-family: "Gilroy";
    color: $blue;
    font-weight: 500;
    position: relative;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
    border: none;
    transition: 0.25s;

    &::after {
      position: absolute;
      content: "";
      display: block;
      left: 0;
      width: 100%;
      bottom: 4px;
      height: 1px;
      background-color: $blue;
      transition: 0.25s;
    }
    &:hover {
      &::after {
        opacity: 0;
        bottom: 2px;
      }
    }
    &:active {
      opacity: 0.65;
    }
  }
  &-add {
    font-size: 16px;
    font-family: "Gilroy";
    color: $blue;
    font-weight: 600;
    flex-shrink: 0;
    position: relative;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
    border: none;
    transition: 0.25s;
    display: flex;
    align-items: center;
    .loader {
      width: 22px;
      height: 22px;
      margin-right: 8px;
    }
    &:hover {
      opacity: 0.75;
      font-weight: 600;
    }
    &:active {
      opacity: 0.6;
    }
  }
  @include mq("md") {
    width: fit-content;
  }

  &_outlined {
    background: transparent;
    border: 1px solid $blue;
    color: $blue;
  }

  &_underline {
    border: none;
    text-decoration: underline;
    border-radius: 0;
    background: none;
  }

  &_clear {
    border: none;
    border-radius: 0;
    background: none;
  }
}
