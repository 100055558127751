@import "../../styles/index.scss";

.o-testimonials {
  &-content {
    .left {
      max-width: 568px;
    }
  }
  &-slider {
    color: $white;
    background-color: $blue;
    border-radius: 16px;
    margin-bottom: 48px;
    line-height: 150%;
    position: relative;
    .swiper-pagination {
      top: calc(100% + 16px);
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        background-color: transparent;
        border: 1px solid $green;
        opacity: 1;
        &.swiper-pagination-bullet-active {
          background-color: $green;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: -8px;
      top: -20px;
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='98' height='78' viewBox='0 0 98 78' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M39.4113 0C40.7309 0 41.6888 1.25547 41.3403 2.52822L28.9986 47.5984C28.4533 49.59 25.6147 49.5495 25.1264 47.5432L22.532 36.8848C22.2255 35.6255 23.1815 34.4075 24.4732 34.5128C29.8721 34.9529 34.3546 36.831 37.9207 40.1471C42.0326 43.9706 44.0886 49.2471 44.0886 55.9765C44.0886 62.5529 41.9565 67.9059 37.6923 72.0353C33.5804 76.0118 28.4025 78 22.1585 78C15.7622 78 10.432 76.0118 6.16783 72.0353C2.05594 67.9059 0 62.5529 0 55.9765C0 53.9882 0.152292 52.0765 0.456877 50.2412C0.761461 48.2529 1.37063 45.9588 2.28438 43.3588C3.19813 40.7588 4.49262 37.3176 6.16783 33.0353L18.9137 1.25551C19.2179 0.497093 19.9528 0 20.77 0H39.4113ZM93.3228 0C94.6424 0 95.6003 1.25547 95.2517 2.52822L82.91 47.5984C82.3647 49.59 79.5261 49.5495 79.0378 47.5432L76.4434 36.8848C76.1369 35.6255 77.0929 34.4075 78.3846 34.5128C83.7835 34.9529 88.266 36.831 91.8322 40.1471C95.9441 43.9706 98 49.2471 98 55.9765C98 62.5529 95.8679 67.9059 91.6037 72.0353C87.4918 76.0118 82.3139 78 76.0699 78C69.6737 78 64.3434 76.0118 60.0793 72.0353C55.9674 67.9059 53.9114 62.5529 53.9114 55.9765C53.9114 53.9882 54.0637 52.0765 54.3683 50.2412C54.6729 48.2529 55.2821 45.9588 56.1958 43.3588C57.1096 40.7588 58.404 37.3176 60.0793 33.0353L72.8251 1.25551C73.1293 0.497093 73.8643 0 74.6814 0H93.3228Z' fill='%2309BC8A'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      width: 98px;
      height: 78px;
    }
  }
  &-slide {
    cursor: context-menu;
    padding: 72px 32px 32px 32px;
    height: auto;
    &.swiper-backface-hidden {
      opacity: 0;
      pointer-events: none;
    }
    &__inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      .img {
        position: absolute;
        width: 96px;
        height: 96px;
        border-radius: 8px;
        overflow: hidden;
        bottom: 0;
        transform: translateY(50%);
        right: -8px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .text {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 16px;
        flex: 1;
      }
      .name {
        font-size: 14px;
        font-weight: 600;
        opacity: 0.6;
        text-transform: uppercase;
      }
      .position {
        font-weight: 600;
        letter-spacing: 5%;
        margin-right: 64px;
      }
    }
  }
  @include mq("md") {
    &-slider {
      margin-right: 68px;
      margin-bottom: 80px;
      margin-left: 20%;
      &::before {
        left: auto;
        top: 32px;
        width: 136px;
        height: 108px;
        right: calc(100% - 24px);
      }
      .swiper-pagination {
        top: calc(100% + 32px);
      }
    }
    &-slide {
      padding: 32px;
      &__inner {
        .img {
          transform: translateY(80px);
          right: -68px;
          width: 136px;
          height: 136px;
        }
      }
    }
  }
  @include mq("xl") {
    &-content {
      display: flex;
      justify-content: space-between;
      .left {
        width: 43.65%;
        max-width: 600px;
      }
      .right {
        width: 56.35%;
      }
    }

    &-slider {
      margin-left: 150px;
      max-width: 560px;
    }
  }
}
