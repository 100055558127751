@import '../../../../styles/helpers/variables.scss';

.sidebar {
  padding: 16px 24px;

  flex: 0 0 344px;

  background-color: $white;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h3 {
      margin: 0;

      font-size: 24px;
      font-weight: 700;

      color: $dark;
    }

    > button {
      border: unset;
      background-color: unset;

      cursor: pointer;

      > svg {
        width: 24px;
        height: 24px;

        fill: $blue;
      }
    }
  }

  > hr {
    margin: 24px 0;

    background-color: $dark;
    opacity: 0.1;
  }

  .field {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > h3 {
        margin: 0;

        font-size: 16px;
        font-weight: 600;
      }

      > button {
        border: unset;
        background-color: unset;

        cursor: pointer;

        > svg {
          width: 24px;
          height: 24px;

          fill: $blue;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      > textarea {
        padding: 16px;

        font-family: inherit;
        font-size: 16px;

        border-radius: 8px;
        border: 1px solid rgba(51, 44, 35, 0.2);
        color: $dark;

        resize: none;
        outline: none;
      }

      > img {
        width: 100%;
        height: auto;

        border-radius: 8px;
      }

      > button {
        width: 100%;
        height: 44px;

        font-size: 16px;

        color: $blue;
      }
    }
  }
}

