.add-payment-method-modal {
  > p {
    margin-bottom: 24px;
  }

  .cards_list {
    margin-bottom: 24px;

    display: flex;
    gap: 8px;

    > li > img {
      width: 56px;
      height: 40px;
    }
  }
}
