@import "../../styles/index.scss";

.m-burger {
  &-btn {
    position: relative;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    background-color: transparent;
    padding: 3px;
    span {
      display: block;
      width: 18px;
      height: 2px;
      background-color: $blue;
      position: absolute;
      left: 3px;
      transition: 0.3s;
      &:first-child {
        top: 50%;
        transform: translate(0, -50%);
      }
      &:nth-child(2) {
        top: 20%;
        transform: translate(0, 0);
      }
      &:nth-child(3) {
        bottom: 20%;
        transform: translate(0, 0);
      }
    }
    &.active {
      span {
        &:first-child {
          opacity: 0;
        }
        &:nth-child(2) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:nth-child(3) {
          bottom: 50%;
          left: 50%;
          transform: translate(-50%, 50%) rotate(-45deg);
        }
      }
    }
  }
  &-menu {
    position: fixed;
    top: 72px;
    z-index: 77;
    left: 0;
    width: 100vw;
    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 72px);
    background-color: $light;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    //.container {
    //    overflow: auto;
    //}
    .m-menu {
      display: flex;
      flex-direction: column;
      li {
        & > * {
          display: inline-block;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
        }
      }
      margin-bottom: 0.75rem;
    }
    .a-btn {
      margin-bottom: 1.5rem;
      max-width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include mq("xl") {
    &-menu {
      display: none;
    }
  }
}
