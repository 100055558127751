@import '../../styles/helpers/variables.scss';

$headerHeight: 112px;

.editor-page {
  display: flex;

  .sidebar-container,
  .template-container {
    height: calc(100vh - $headerHeight);
    overflow-y: scroll;
  }

  .template-container {
    padding: 24px 24px;
  }
}

