@import "../../styles/index.scss";

.progress-bar {
  width: 100%;
  background: #d9d9d9;
  height: 4px;
  display: block;
  margin-bottom: 16px;
  border-radius: 2px;
  position: relative;

  &__line {
    height: 100%;
    background: $blue;
    display: block;
  }
}
