@import "../../styles/index.scss";
.staff-suggestion {
  &__inputs {
    display: grid;
    gap: 16px;
    width: 80%;
    margin-bottom: 16px;
    margin-right: 16px;
  }

  &__stuff {
    margin-bottom: 40px;
    display: flex;
  }

  &__photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 8px;
    width: 128px;
    height: 128px;
    position: relative;
  }

  &__photo {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__photo-title {
    color: $blue;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  &__uploaded {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__uploaded-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__uploaded-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 100;
    width: 24px;
    height: 24px;
    background: white;
    color: $blue;
  }
}
