@import '../helpers/index.scss';


.input {
  input,
  textarea {
    display: block;
    padding: 16px;
    font-size: 16px;
    font-family: "Gilroy";
    line-height: 150%;
    color: $dark;
    border: 1px solid rgba(51, 44, 35, 0.2);
    border-radius: 8px;
    background-color: transparent;
    width: 100%;
    &::placeholder {
      color: rgba(51, 44, 35, 0.6);
    }

    &.error {
      border-color: red;
      outline: none;
    }
  }
  textarea {
    resize: none;
  }
}
