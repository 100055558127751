@import '../../styles/helpers/mixins.scss';
@import '../../styles/helpers/variables.scss';

.signup_page {
  >main {
    @include mq('xl') {
      padding: 56px 32px;
    }

    padding: 24px 16px 40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    >h1 {
      margin-bottom: 24px;

      font-size: 40px;
      font-weight: 700;
      line-height: 130%;
    }

    >form {
      max-width: 520px;
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 24px;

      >button {
        max-width: unset;
        width: 100%;
      }

      .form_fields {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .row {
          display: flex;
          gap: 16px;

          >label {
            flex: 1;
          }
        }

        label {
          display: flex;
          flex-direction: column;
          gap: 4px;

          >span {
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
          }
        }

        .forgot_password {
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;
          text-align: right;

          >button {
            font-size: inherit;
            font-family: inherit;
            text-decoration: underline;

            border: none;
            background: none;
            color: $blue;
            cursor: pointer;
          }
        }
      }


      .sign_in {
        text-align: center;
        font-weight: 500;
        line-height: 150%;

        >a {
          color: $blue;
        }
      }

      .terms {
        font-size: 12px;
        text-align: center;
        opacity: 0.5;

        > span {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }
}