@import '../../../../styles/helpers/variables.scss';

.editable-input {
  &__header {
    display: flex;
    justify-content: space-between;

    > h4 {
      color: $dark;

      font-size: 18px;
      font-weight: 500;
      line-height: 150%;

      > span {
        color: #09bc8a;

        font-size: 15px;
        font-weight: 500;
        line-height: 150%;

        opacity: 0.5;
      }
    }

    > button {
      color: $blue;
      background-color: unset;
      border: unset;

      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;

      cursor: pointer;
    }
  }

  .input {
    height: 56px;
    padding: 16px;
  }
}

