@import '../helpers/index.scss';

.o-section {
  padding-top: 32px;
  padding-bottom: 32px;
  &.o-features {
    overflow: hidden;
  }
  &.o-faq {
    padding-bottom: 64px;
  }
  &.o-ready {
    padding-top: 64px;
    padding-bottom: 64px;
    background-color: $blue-light;
  }
  @include mq("md") {
    &.o-ready {
      padding-top: 96px;
      padding-bottom: 96px;
    }
  }
  @include mq("lg") {
    padding-top: 48px;
    padding-bottom: 48px;
    &.o-faq {
      padding-bottom: 96px;
    }
    &.o-ready {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }

  @include mq("xl") {
    padding-top: 60px;
    padding-bottom: 60px;
    &.o-faq {
      padding-bottom: 120px;
    }
  }
}
