@import '../../../../styles/helpers/mixins.scss';

.card-details-form {
  > h4 {
    margin-bottom: 4px;

    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
  }

  > form {
    @include mq('md') {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;

      > div:first-of-type {
        grid-column: span 2;
      }
    }

    display: grid;
    gap: 16px;
  }
}

