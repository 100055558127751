@import '../../styles/helpers/mixins.scss';
@import '../../styles/helpers/variables.scss';

.account-layout {
  .upgrade {
    @include mq('md', max) {
      padding: 16px;
      gap: 16px;

      flex-direction: column;

      &__button {
        min-width: 100%;
      }
    }

    padding: 8px 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    background-color: $blue;

    > p {
      font-weight: 500;
      line-height: 150%;

      color: $white;
    }

    &__button {
      height: unset;
      padding: 8px 24px;

      font-size: 16px;

      border: 1px solid $white;
      color: $white;
      background-color: transparent;
    }
  }
}

