@import '../../../../styles/helpers/mixins.scss';
@import '../../../../styles/helpers/variables.scss';

.payment-method {
  @include mq('md', 'max') {
    max-width: none;
  }

  max-width: 328px;
  height: 120px;
  padding: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 8px;
  background-color: $white;

  &.active {
    border: 2px solid $blue;
  }

  > img {
    width: auto;
    height: 78px;
  }

  &__content {
    display: flex;
    flex-direction: column;

    &__number {
      font-size: 18px;
      font-weight: 500;

      color: $dark;
    }

    &__expires {
      font-size: 16px;
      font-weight: 500;

      color: $dark;
      opacity: 0.4;
    }

    &__actions {
      margin-top: 8px;

      display: flex;

      > button {
        font-family: inherit;
        font-size: 16px;
        font-weight: 500;

        border: unset;
        background-color: unset;
        color: $blue;

        cursor: pointer;
      }

      > hr {
        margin: 0 16px;

        background-color: $dark;
        opacity: 0.6;
      }
    }
  }

  &--placeholder {
    font-size: 18px;
    font-weight: 500;
    text-align: center;

    color: $blue;

    cursor: pointer;
  }
}

