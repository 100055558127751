@import "../../styles/index.scss";

.o-footer {
  background-color: $dark-blue;
  color: $light;
  line-height: 150%;
  &-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .a-logo {
      margin-bottom: 16px;
    }
    .mail {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 20px;
      transform: 0.25s;
      display: inline-block;
      margin-bottom: 16px;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
      }
    }
    .m-menu {
      margin-bottom: 32px;
      font-weight: 500;
      li {
        & > * {
          display: inline-block;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      }
    }
    .bottom {
      p {
        width: 100%;
        margin-bottom: 32px;
        font-size: 14px;
        font-weight: 500;
      }
      .links {
        display: flex;
        justify-content: center;
        & > * {
          margin-right: 2rem;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 500;
          text-decoration: none;
          padding-top: 4px;
          padding-bottom: 4px;
          position: relative;
          &:hover {
            &::after {
              transform: translateY(3px);
              opacity: 0;
            }
          }
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 5px;
            width: 100%;
            height: 1px;
            background-color: $blue-light;
            display: block;
            transition: 0.25s;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  @include mq("md") {
    &-row {
      .m-menu {
        order: 1;
        display: flex;
        flex-direction: row;
        li {
          margin-right: 2rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .a-logo {
        order: 2;
      }
      .mail {
        order: 3;
      }
      .bottom {
        order: 4;
        p {
          margin-bottom: 16px;
        }
      }
    }
  }
}
