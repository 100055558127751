@import '../../styles/helpers/index.scss';

.open-hours {
  display: grid;
  gap: 16px;

  &__heading {
    @include mq('xs') {
      flex-direction: column;
    }
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 0;
    font-weight: 600;

    &_invis {
      opacity: 0;
    }
  }

  &__button {
    @include mq('xs') {
      margin-left: -32px;
    }
    white-space: nowrap;
  }

  &__select {
    width: 80%;
    height: 56px;

    & input {
      padding: 8px !important;
    }
  }

  &__select_sm {
    width: 20%;
    margin-left: 16px;
  }

  &__select-wrapper {
    @include mq('xs') {
      flex-direction: column;
    }

    display: flex;
    gap: 16px;
    margin-bottom: 40px;
  }

  &__select-section {
    display: flex;
  }

  &__select-block {
    width: 50%;
    @include mq('xs') {
      width: 100%;
    }

    @include mq('xl') {
      width: 50%;
    }
  }

  &__input-special {
    width: 80%;
    margin-right: 16px;
  }

  &__select_special {
    & input {
      padding: 8px !important;
    }
  }

  &__label-special {
    font-size: 18px;
    line-height: 27px;
    margin-top: 16px;

    &_invis {
      opacity: 0;
    }
  }

  &__special-wrapper {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
}
