@import '../../styles/helpers/mixins.scss';
@import '../../styles/helpers/variables.scss';

.dashboard {
  .tabs {
    padding: 24px 0 0;

    display: flex;

    overflow-x: scroll;

    > li {
      opacity: 0.6;

      &.active {
        border-bottom: 2px solid $blue;
        opacity: 1;
      }

      > button {
        padding: 8px 16px;

        font-size: 18px;
        font-weight: 500;
        text-wrap: nowrap;

        border: none;
        background-color: transparent;
        color: $dark;

        cursor: pointer;
      }
    }
  }

  &__content {
    @include mq('xl') {
      padding: 32px;

      display: flex;
      flex-direction: column;
      gap: 40px;

      &--group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;
      }
    }

    padding: 24px 16px;
  }
}

