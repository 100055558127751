@import "../../styles/index.scss";

.m-faq {
  &-list {
    max-width: 722px;
    margin-left: auto;
    margin-right: auto;
  }
  &-item {
    border-bottom: 1px solid $blue;
    line-height: 150%;
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 1.5rem;
      padding-top: 1.5rem;
      cursor: pointer;
      &::after {
        content: "";
        display: block;
        transition: 0.4s;
        width: 24px;
        height: 24px;
        margin-left: 1rem;
        background-size: contain;
        background-repeat: no-repeat;
        flex-shrink: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='11' y='4' width='2' height='16' rx='1' fill='%231E96FC'/%3E%3Crect x='4' y='11' width='16' height='2' rx='1' fill='%231E96FC'/%3E%3C/svg%3E%0A");
      }
      & ~ .desc {
        transition: 0.3s;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        padding-bottom: 0px;
      }
      &.active {
        &::after {
          transform: rotate(135deg);
        }
        & ~ .desc {
          max-height: 640px;
          opacity: 1;
          padding-bottom: 24px;
        }
      }
    }
  }
}
